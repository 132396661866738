import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { createAi } from '../../../../actions';

import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import AiLibraryForm from '../AiLibraryForm/AiLibraryForm';

const AiLibraryCreate = ({
  createCanceler,
  dispatch,
  history,
  location,
}) => {
  const error = useSelector(({ ai }) => ai.error);

  const createNewAi = useCallback((data) => {
    const canceler = createCanceler();

    return dispatch(createAi(data, canceler.token));
  }, [createCanceler, dispatch]);

  const handleCreate = useCallback(async (formData, callback) => {
    try {
      await createNewAi(formData);

      toast('success', 'Нейросеть добавлена!');

      history.push(`/ai-library${formData.lang !== 'ru' ? `?lang=${formData.lang}` : ''}`);
    } catch (err) {
      toast('error', 'Прозошла ошибка');
    }

    callback();
  }, [history, createNewAi]);

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
      viewClass="ai-library-create"
      title="Добавить нейросеть"
      content={(
        <AiLibraryForm
          buttonText="Создать нейросеть"
          location={location}
          onSubmit={handleCreate}
        />
      )}
    />
  );
};

AiLibraryCreate.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRequest(memo(AiLibraryCreate));
