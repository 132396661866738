import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, {
  memo, useEffect,
  useRef, useState,
} from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { languages } from '../../../../constants/common';

import { toast } from '../../../../utils';

import Preloader from '../../../UI/Preloader/Preloader';

import ImageUploader from '../ImageUploader/ImageUploader';

import './AiLibraryGroupForm.scss';

const EMPTY_FORM = {
  name: '',
  image_path: '',
  lang: 'ru',
};

const AiLibraryGroupForm = ({
  buttonText,
  data,
  disabled,
  location,
  onSubmit = () => {},
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [formData, setFormData] = useState(
    cloneDeep(
      data?._id
        ? data
        : { ...EMPTY_FORM, lang: queryString.parse(location.search)?.lang || 'ru' },
    ),
  );

  const [file, setFile] = useState(null);
  const [formImageUrl, setFormImageUrl] = useState(formData.image_path);

  const imageUploaderRef = useRef(null);

  const handleChangeFormData = (props) => {
    setFormData((prev) => ({ ...prev, ...props }));
  };

  const handleSubmit = async () => {
    if (!file && !formData.image_path) return toast('error', 'Отсутствует изображение');

    setIsSubmiting(true);

    const image_path = file ? await imageUploaderRef.current.uploadFileCover() : formData.image_path;

    if (!formData.name?.trim()) return toast('error', 'Нет названия');

    return onSubmit({ ...formData, image_path }, () => setIsSubmiting(false));
  };

  useEffect(() => {
    if (file) setFormImageUrl(URL.createObjectURL(file));
  }, [file]);

  return (
    <div className="ai-library-group-form">
      <span className="ai-library-group-form__link">
        <Link to={`/ai-library/groups${formData.lang !== 'ru' ? `?lang=${formData.lang}` : ''}`}>Вернуться назад</Link>
      </span>

      <div className="ai-library-group-form__row">
        <div className="ai-library-group-form__label">Название:</div>

        <Form.Control
          disabled={disabled}
          value={formData.name}
          onChange={(e) => handleChangeFormData({ name: e.target.value })}
        />
      </div>

      <div className="ai-library-group-form__row">
        <div className="ai-library-group-form__label">Язык:</div>

        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
            { formData.lang && languages[formData.lang] }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              Object.keys(languages).map((lang) => (
                formData.lang !== lang ? (
                  <Dropdown.Item
                    as="button"
                    eventKey={lang}
                    key={lang}
                    onSelect={(value) => handleChangeFormData({ lang: value })}
                  >
                    { languages[lang] }
                  </Dropdown.Item>
                ) : null
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="ai-library-group-form__row">
        <div className="ai-library-group-form__label">Изображение:</div>

        <ImageUploader
          caption="Рекомендуемый размер 128x128"
          compact
          formImageUrl={formImageUrl}
          handleSetFile={setFile}
          file={file}
          ref={imageUploaderRef}
        />
      </div>

      <Button
        className="btn-primary"
        disabled={disabled || !formData.name?.trim() || isSubmiting || (!file && !formData.image_path)}
        onClick={handleSubmit}
      >
        { isSubmiting && <FontAwesomeIcon className="mr-2" spin icon={['fa', 'spinner-third']} /> }

        { buttonText }

        { disabled && <Preloader inline small caption="" /> }
      </Button>
    </div>
  );
};

AiLibraryGroupForm.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image_path: PropTypes.string,
    lang: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  location: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default memo(AiLibraryGroupForm);
