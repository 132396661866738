import { connect } from 'react-redux';

import {
  RESET_AI_LIST,
  buildUpdateAiGroups,
  bulkUpdateAIs,
  deleteAi,
  deleteAiGroup,
  getAiGroupsList,
  getAiList,
  updateAi,
  updateAiGroup,
} from '../../../actions';

import AiLibrary from './AiLibrary';

const mapStateToProps = (state) => ({
  ai: state.ai,
});

const mapDispatchToProps = (dispatch) => ({
  onBulkUpdateAIs: (data, requestToken) => dispatch(bulkUpdateAIs(data, requestToken)),
  onBulkUpdateAiGroups: (data, requestToken) => dispatch(buildUpdateAiGroups(data, requestToken)),
  onDeleteAi: (id, requestToken) => dispatch(deleteAi(id, requestToken)),
  onDeleteAiGroup: (id, requestToken) => dispatch(deleteAiGroup(id, requestToken)),
  onGetAiList: (params, requestToken) => dispatch(getAiList(params, requestToken)),
  onGetAiGroupsList: (params, requestToken) => dispatch(getAiGroupsList(params, requestToken)),
  onUpdateAi: (id, data, requestToken) => dispatch(updateAi(id, data, requestToken)),
  onUpdateAiGroup: (id, data, requestToken) => dispatch(updateAiGroup(id, data, requestToken)),
  onUnload: () => dispatch({ type: RESET_AI_LIST }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AiLibrary);
