export const BULK_UPDATE_AI = 'BULK_UPDATE_AI';
export const BULK_UPDATE_AI_GROUPS = 'BULK_UPDATE_GROUP_AI';
export const CREATE_AI = 'CREATE_AI';
export const CREATE_AI_GROUP = 'CREATE_AI_GROUP';
export const DELETE_AI = 'DELETE_AI';
export const DELETE_AI_GROUP = 'DELETE_AI_GROUP';
export const GET_AI = 'GET_AI';
export const GET_AI_GROUP = 'GET_AI_GROUP';
export const GET_AI_LIST = 'GET_AI_LIST';
export const GET_AI_GROUPS_LIST = 'GET_AI_GROUPS_LIST';
export const RESET_AI_LIST = 'RESET_AI_LIST';
export const UPDATE_AI = 'UPDATE_AI';
export const UPDATE_AI_GROUP = 'UPDATE_GROUP_AI';

/* --------------- */
/* Action creators */

/* --------------- */

export function createAi(data, requestToken) {
  return {
    type: CREATE_AI,
    request: {
      method: 'post',
      url: '/admin/ai-library/create',
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function createAiGroup(data, requestToken) {
  return {
    type: CREATE_AI_GROUP,
    request: {
      method: 'post',
      url: '/admin/ai-library/groups/create',
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function getAi(id, requestToken) {
  return {
    type: GET_AI,
    request: {
      method: 'get',
      url: `/ai-library/${id}`,
      requestToken,
      apiv2: true,
    },
  };
}

export function getAiGroup(id, requestToken) {
  return {
    type: GET_AI_GROUP,
    request: {
      method: 'get',
      url: `/ai-library/groups/${id}`,
      requestToken,
      apiv2: true,
    },
  };
}

export function getAiList(params, requestToken) {
  return {
    type: GET_AI_LIST,
    request: {
      method: 'get',
      url: '/ai-library',
      params,
      requestToken,
      apiv2: true,
    },
  };
}

export function getAiGroupsList(params, requestToken) {
  return {
    type: GET_AI_GROUPS_LIST,
    request: {
      method: 'get',
      url: '/ai-library/groups',
      requestToken,
      params,
      apiv2: true,
    },
  };
}

export function updateAi(id, data, requestToken) {
  return {
    type: UPDATE_AI,
    request: {
      method: 'patch',
      url: `/admin/ai-library/${id}`,
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function updateAiGroup(id, data, requestToken) {
  return {
    type: UPDATE_AI_GROUP,
    request: {
      method: 'patch',
      url: `/admin/ai-library/groups/${id}`,
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function bulkUpdateAIs(data, requestToken) {
  return {
    type: BULK_UPDATE_AI,
    request: {
      method: 'post',
      url: '/admin/ai-library/order',
      data,
      requestToken,
      apiv2: true,
    },
    payload: data,
  };
}

export function buildUpdateAiGroups(data, requestToken) {
  return {
    type: BULK_UPDATE_AI_GROUPS,
    request: {
      method: 'post',
      url: '/admin/ai-library/groups/order',
      data,
      requestToken,
      apiv2: true,
    },
    payload: data,
  };
}

export function deleteAi(id, requestToken) {
  return {
    type: DELETE_AI,
    request: {
      method: 'delete',
      url: `/admin/ai-library/${id}`,
      requestToken,
      apiv2: true,
    },
    payload: { id },
  };
}

export function deleteAiGroup(id, requestToken) {
  return {
    type: DELETE_AI_GROUP,
    request: {
      method: 'delete',
      url: `/admin/ai-library/groups/${id}`,
      requestToken,
      apiv2: true,
    },
    payload: { id },
  };
}

/* ------ */
/* Thunks */

/* ------ */
