import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import errorMessages from '../../../../../constants/errors';

import { toast } from '../../../../../utils';

import {
  createCreativeStudioStyleCategory,
  deleteCreativeStudioStyleCategory,
  getCreativeStudioStyleCategories,
  orderCreativeStudioStyleCategories,
  saveCreativeStudioStyleCategoriesOrder,
  updateCreativeStudioStyleCategory,
} from '../../../../../actions';

import {
  selectCreativeStudioStyleCategoriesDocs,
  selectCreativeStudioStyleCategoriesError,
  selectCreativeStudioStyleCategoriesIsFetching,
  selectCreativeStudioStyleCategoriesIsOrdered,
} from '../../../../../store/selectors';

import sweetAlert from '../../../../HOCs/sweetAlert';
import withRequest from '../../../../HOCs/withRequest';

import { useQueryLang } from '../../../../hooks';

import View from '../../../../Layout/View/View';

import LanguageDropdown from '../../../../Includes/LanguageDropdown/LanguageDropdown';
import CreativeStudioNav from '../../../../Includes/CreativeStudio/CreativeStudioNav/CreativeStudioNav';
import CreativeStudioImagesSubnav from '../../../../Includes/CreativeStudio/CreativeStudioImagesSubnav/CreativeStudioImagesSubnav';

import CreativeStudioImagesStylesCategory from './CreativeStudioImagesStylesCategory/CreativeStudioImagesStylesCategory';
import CreativeStudioImagesStylesCategoryForm from './CreativeStudioImagesStylesCategoryForm/CreativeStudioImagesStylesCategoryForm';

const CreativeStudioImagesStyles = ({
  createCanceler, dispatch, history, location,
}) => {
  const isError = useSelector(selectCreativeStudioStyleCategoriesError);
  const isFetching = useSelector(selectCreativeStudioStyleCategoriesIsFetching);
  const isOrdered = useSelector(selectCreativeStudioStyleCategoriesIsOrdered);
  const docs = useSelector(selectCreativeStudioStyleCategoriesDocs);

  const [isCreateNewCategory, setIsCreateNewCategory] = useState(false);
  const [isCategoryCreating, setIsCategoryCreating] = useState(false);
  const [isCategoryUpdating, setIsCategoryUpdating] = useState(false);
  const [isCategoriesOrderSaving, setIsCategoriesOrderSaving] = useState(false);
  const [isCategoryDeleting, setIsCategoryDeleting] = useState(false);

  const { currentLang } = useQueryLang(location);

  const handleGetStyleCategories = useCallback(() => {
    const requestGetStyleCategoriesCanceler = createCanceler();

    dispatch(getCreativeStudioStyleCategories('image', currentLang, requestGetStyleCategoriesCanceler.token));
  }, [dispatch, currentLang, createCanceler]);

  useEffect(() => {
    handleGetStyleCategories();
  }, [handleGetStyleCategories]);

  const handleDragEnd = useCallback((result) => {
    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination) return;

    if (destination.index === source.index) return;

    if (type === 'style-category') {
      dispatch(orderCreativeStudioStyleCategories({
        sizeId: draggableId, // id перемещаемого итема
        currentPosition: source.index, // индекс текущего перемещаемого итема
        newPosition: destination.index, // индекс новой позиции в массиве
      }));
    }
  }, [dispatch]);

  const handleSaveOrder = useCallback(() => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите сохранить изменение порядка?',
    }).then((result) => {
      if (!result.value) return;

      setIsCategoriesOrderSaving(true);

      const prepared = docs.map((item, order) => ({ _id: item._id, order }));

      const requestSaveOrderCanceler = createCanceler();

      dispatch(saveCreativeStudioStyleCategoriesOrder(prepared, requestSaveOrderCanceler.token))
        .then(() => {
          toast('success', 'Список успешно отсортирован');

          setIsCategoriesOrderSaving(false);
        })
        .catch(() => {
          toast('error', 'Что-то пошло не так');

          setIsCategoriesOrderSaving(false);
        });
    });
  }, [createCanceler, dispatch, docs]);

  const handleCreateStyleCategory = useCallback((name) => {
    if (name === '') return;

    setIsCategoryCreating(true);

    const requestCreateCategoryCanceler = createCanceler();

    dispatch(createCreativeStudioStyleCategory({
      lang: currentLang,
      type: 'image',
      name: name.trim(),
    }, requestCreateCategoryCanceler.token))
      .then(() => {
        toast('success', 'Группа создана!');

        setIsCreateNewCategory(false);
        setIsCategoryCreating(false);
      })
      .catch(() => {
        toast('error', 'Ошибка');

        setIsCategoryCreating(false);
      });
  }, [createCanceler, dispatch, currentLang]);

  const handleCancelCreateStyleCategory = useCallback(() => setIsCreateNewCategory(false), []);

  const handleUpdateStyleCategory = useCallback((id, data, successCallback) => {
    setIsCategoryUpdating(true);

    const requestUpdateCategoryCanceler = createCanceler();

    dispatch(updateCreativeStudioStyleCategory(id, data, requestUpdateCategoryCanceler.token))
      .then(() => {
        toast('success', 'Группа обновлена!');

        setIsCategoryUpdating(false);

        successCallback();
      })
      .catch(() => {
        setIsCategoryUpdating(false);

        toast('error', 'Ошибка');
      });
  }, [createCanceler, dispatch]);

  const handleDeleteStyleCategory = useCallback((id) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить группу?',
    }).then((result) => {
      if (!result.value) return;

      setIsCategoryDeleting(true);

      const requestDeleteCategoryCanceler = createCanceler();

      dispatch(deleteCreativeStudioStyleCategory(id, requestDeleteCategoryCanceler.token))
        .then(() => {
          toast('success', 'Группа удалена!');

          setIsCategoryDeleting(false);
        })
        .catch(() => {
          setIsCategoryDeleting(false);

          toast('error', 'Ошибка');
        });
    });
  }, [createCanceler, dispatch]);

  const handleChangeLang = useCallback((lang) => history.push(lang === 'ru' ? '/creative-studio/images/styles' : `/creative-studio/images/styles?lang=${lang}`), [history]);

  const renderLanguagesDropdown = () => (
    <div className="object-categories-language">
      <LanguageDropdown
        currentLang={currentLang}
        disabled={isFetching}
        onChange={handleChangeLang}
      />
    </div>
  );

  const isActionDisabled = isFetching || isCategoriesOrderSaving || isCategoryCreating || isCategoryUpdating || isCategoryDeleting;

  const renderActionButtons = () => (
    <div className="creative-studio__actions">
      {!isFetching && (
        <Button
          disabled={isActionDisabled || isCreateNewCategory}
          variant="success"
          onClick={() => setIsCreateNewCategory(true)}
        >
          Добавить группу
        </Button>
      )}
      {!isFetching && isOrdered && (
        <Button
          disabled={isActionDisabled}
          onClick={handleSaveOrder}
        >
          Сохранить изменение порядка
        </Button>
      )}
    </div>
  );

  const renderHeader = () => (
    <div className="creative-studio__header">
      <div className="creative-studio__row creative-studio__uppernav">
        <CreativeStudioNav />
        {renderLanguagesDropdown()}
      </div>
      <div className="creative-studio__subnav">
        <CreativeStudioImagesSubnav />
      </div>
      {renderActionButtons()}
    </div>
  );

  const renderList = () => (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        droppableId="all-style-categories"
        direction="vertical"
        type="style-category"
      >
        {(provided) => ( // eslint-disable-line
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="creative-studio__styles-categories"
          >
            {docs.length > 0 && !isFetching && docs.map((item, index) => (
              <Draggable draggableId={String(item._id)} index={index} key={item._id} isDragDisabled={isActionDisabled}>
                {(provided2, snapshot) => (
                  <div
                    {...provided2.draggableProps}
                    ref={provided2.innerRef}
                    className={classNames('template-bunch__item', {
                      'template-bunch__item_dragging': snapshot.isDragging,
                    })}
                  >
                    <CreativeStudioImagesStylesCategory
                      data={item}
                      dragHandleProps={provided2.dragHandleProps}
                      isDisabled={isActionDisabled}
                      location={location}
                      onDelete={handleDeleteStyleCategory}
                      onUpdate={handleUpdateStyleCategory}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  const renderContent = () => (
    <>
      {renderList()}
      {isCreateNewCategory && (
        <div className="template-bunch__item creative-studio__styles-category">
          <CreativeStudioImagesStylesCategoryForm
            defaultName=""
            isDisabled={isActionDisabled}
            onCancel={handleCancelCreateStyleCategory}
            onComplete={handleCreateStyleCategory}
          />
        </div>
      )}
      {docs.length > 2 ? renderActionButtons() : null}
    </>
  );

  return (
    <View
      errorMessage={errorMessages[isError?.message]}
      isError={isError && ['admin_privileges_required', 'first_request_failed'].includes(isError?.message)}
      isFetching={isFetching}
      header={renderHeader()}
      preloaderCaption="Загружаем..."
      title="Креативная студия"
      viewClass="creative-studio"
      content={renderContent()}
    />
  );
};

CreativeStudioImagesStyles.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRequest(CreativeStudioImagesStyles);
