import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreativeStudioImagesStylesCategoryForm = ({
  defaultName, dragHandleProps, isDisabled, onCancel, onComplete,
}) => {
  const [name, setName] = useState(defaultName);

  const handleNameChange = (e) => setName(e.target.value);

  const handleComplete = () => onComplete(name);

  return (
    <div className="creative-studio__styles-category-header">
      <Form.Control
        autoFocus
        className="creative-studio__styles-category-form-field"
        disabled={isDisabled}
        placeholder="Введите название группы..."
        value={name}
        onChange={handleNameChange}
      />
      <div className="creative-studio__styles-category-actions">
        <button
          className="template-bunch-item__button creative-studio__styles-category-button creative-studio__styles-category-button_complete"
          disabled={name === '' || name === defaultName || isDisabled}
          type="button"
          onClick={handleComplete}
        >
          <FontAwesomeIcon icon={['fas', 'check']} />
        </button>
        <button
          className="template-bunch-item__button creative-studio__styles-category-button creative-studio__styles-category-button_delete"
          disabled={isDisabled}
          type="button"
          onClick={onCancel}
        >
          <FontAwesomeIcon icon={['far', 'times']} />
        </button>
        <div {...(dragHandleProps || {})} />
      </div>
    </div>
  );
};

CreativeStudioImagesStylesCategoryForm.propTypes = {
  defaultName: PropTypes.string.isRequired,
  dragHandleProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default memo(CreativeStudioImagesStylesCategoryForm);
