import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useImperativeHandle } from 'react';

import { getSignedUrl, sendFileToS3 } from '../../../../actions';

import { toast } from '../../../../utils';

import withForwardRef from '../../../HOCs/withForwardRef';
import withRequest from '../../../HOCs/withRequest';

import './ImageUploader.scss';

const ImageUploader = ({
  caption,
  createCanceler,
  compact,
  dispatch,
  file,
  formImageUrl,
  handleSetFile,
  forwardedRef,
}) => {
  const uploadFileCover = useCallback(() => {
    const { name, type } = file;

    const requestGetSignedUrlCanceler = createCanceler();

    return dispatch(getSignedUrl({ file_name: name, file_type: type, upload_type: 'template_category_cover' }, requestGetSignedUrlCanceler.token))
      .then((response) => {
        const requestSendToS3Canceler = createCanceler();

        return dispatch(sendFileToS3(response.signedRequest, file, requestSendToS3Canceler.token))
          .then(() => response.url)
          .catch(() => toast('error', 'Не удалось загрузить файл по подписанному URL'));
      })
      .catch(() => toast('error', 'Не удалось получить подписанный URL'));
  }, [createCanceler, file, dispatch]);

  useImperativeHandle(forwardedRef, () => ({
    uploadFileCover,
  }), [uploadFileCover]);

  return (
    <div>
      <div className={classNames('image-uploader', {
        'with-image': Boolean(formImageUrl),
        compact,
      })}
      >
        { formImageUrl && <img src={formImageUrl} alt="" /> }

        <input
          accept="image/png,image/jpeg"
          id="image-uploader"
          type="file"
          hidden
          onChange={(e) => handleSetFile(e.target?.files?.[0])}
        />

        <label htmlFor="image-uploader">
          {/* eslint-disable-line jsx-a11y/label-has-associated-control */}

          <div>
            <FontAwesomeIcon icon="upload" />

            <span>Загрузите изображение</span>
          </div>
        </label>

      </div>

      { caption && <p>{ caption }</p> }
    </div>
  );
};

ImageUploader.propTypes = {
  caption: PropTypes.string,
  createCanceler: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  file: PropTypes.object,
  formImageUrl: PropTypes.string,
  forwardedRef: PropTypes.any,
  handleSetFile: PropTypes.func.isRequired,
};

export default withForwardRef(withRequest(ImageUploader));
