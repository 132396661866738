import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ai from './ai';
import auth from './auth';
import common from './common';
import profile from './profile';
import users from './users';
import invoices from './invoices';
import landing from './landing/landing-state';
import partners from './partners';
import mailing from './mailing';
import promocodes from './promocodes';
import categories from './templates/categories';
import groups from './templates/groups';
import groupsCategories from './templates/group-categories';
import sizes from './templates/sizes';
import templates from './templates/templates';
import videos from './videos';
import ideas from './ideas';
import tags from './tags';
import objectCategories from './objects/categories';
import objectCategoryObjects from './objects/objects';
import services from './services';
import accessIPAddresses from './access/ip-addresses';
import accessAdmins from './access/admins';
import companies from './companies';
import creativeStudioStyleCategories from './creative-studio/styles';

export default (history) => combineReducers({
  router: connectRouter(history),
  ai,
  auth,
  common,
  profile,
  users,
  invoices,
  landing,
  partners,
  mailing,
  promocodes,
  categories,
  groups,
  groupsCategories,
  sizes,
  templates,
  videos,
  ideas,
  tags,
  objectCategories,
  objectCategoryObjects,
  services,
  accessIPAddresses,
  accessAdmins,
  companies,
  creativeStudioStyleCategories,
});
