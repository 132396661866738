import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useQueryLang } from '../../../hooks';

const CreativeStudioImagesSubnav = () => {
  const location = useLocation();

  const { currentLang, defaultLang } = useQueryLang(location);

  const queryParams = currentLang !== defaultLang ? `?lang=${currentLang}` : '';

  return (
    <div className="subnav">
      <NavLink activeClassName="is-active" to={`/creative-studio/images/community${queryParams}`}>
        Сообщество
      </NavLink>
      <NavLink activeClassName="is-active" to={`/creative-studio/images/styles${queryParams}`}>
        Стили
      </NavLink>
    </div>
  );
};

export default CreativeStudioImagesSubnav;
