import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { requestCanceler } from '../../../middlewares/api';

import { languages } from '../../../constants/common';
import errorMessages from '../../../constants/errors';

import { toast } from '../../../utils';

import sweetAlert from '../../HOCs/sweetAlert';

import View from '../../Layout/View/View';

import Language from '../../Includes/LanguageDropdown/LanguageDropdown';

import NoMedia from '../../UI/NoMedia/NoMedia';

import './AiLibrary.scss';

class AiLibrary extends React.PureComponent {
  requestCancelers = [];

  languages = Object.keys(languages);

  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (
      prevProps.location?.search !== location?.search
      || prevProps.location?.pathname !== location?.pathname
    ) this.getList();
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getList = () => {
    const { onGetAiGroupsList, onGetAiList, location } = this.props;

    const currentListType = location.pathname === '/ai-library/groups' ? 'groups' : 'ai';

    const requestGetListCanceler = requestCanceler();

    this.requestCancelers.push(requestGetListCanceler);

    const fetchFunction = currentListType === 'ai' ? onGetAiList : onGetAiGroupsList;

    fetchFunction({
      lang: queryString.parse(location?.search)?.lang || 'ru',
    }, requestGetListCanceler.token).catch(() => {});
  };

  handleChangeQuery = ({ list, lang }) => {
    const { history, location } = this.props;

    const newListType = list || (location?.pathname === '/ai-library/groups' ? 'groups' : 'ai');
    const newLang = lang || queryString.parse(location?.search)?.lang || 'ru';

    const newQuery = newLang !== 'ru' ? `?${queryString.stringify({ lang: newLang })}` : '';

    history.push(`/ai-library${newListType === 'groups' ? '/groups' : ''}${newQuery}`);
  };

  handleClickEdit = (id, listType) => {
    const { history } = this.props;

    history.push(`/ai-library/${listType === 'groups' ? 'groups/' : ''}${id}`);
  };

  handleClickDelete = (id, listType) => {
    const { onDeleteAi, onDeleteAiGroup } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, я уверен',
      showCancelButton: true,
      type: 'warning',
      title: 'Вы уверены?',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      html: `Вы действительно хотите удалить ${listType === 'ai' ? 'нейросеть' : 'группу'}?`,
    }).then((result) => {
      if (!result.value) return;

      const requestDeleteGroupCanceler = requestCanceler();

      this.requestCancelers.push(requestDeleteGroupCanceler);

      const deleteFunction = listType === 'ai' ? onDeleteAi : onDeleteAiGroup;

      deleteFunction(id, requestDeleteGroupCanceler.token)
        .then(() => toast('success', `${listType === 'ai' ? 'Нейросеть' : 'Группу'} удалена!`))
        .catch((error) => {
          const message = errorMessages[error.message] || error.message || 'Ошибка';

          toast('error', message);
        });
    });
  };

  renderLanguagesDropdown = () => {
    const { ai, location } = this.props;

    const defaultLang = queryString.parse(location?.search)?.lang;
    const currentLang = defaultLang && this.languages.includes(defaultLang) ? defaultLang : this.languages[0];

    return (
      <Language
        currentLang={currentLang}
        disabled={ai?.isFetching || ai?.isOrderingAiGroups}
        onChange={(lang) => this.handleChangeQuery({ lang })}
      />
    );
  };

  handleDragEnd = (result) => {
    const {
      ai,
      onBulkUpdateAIs,
      onBulkUpdateAiGroups,
    } = this.props;

    const { destination, source, type } = result;

    if (!destination || destination.index === source.index) return;

    const updateFunction = type === 'ai-groups' ? onBulkUpdateAiGroups : onBulkUpdateAIs;
    const newDocs = [...type === 'ai-ai' ? ai.aiList.docs : ai.groups.docs];

    newDocs.splice(destination.index, 0, newDocs.splice(source.index, 1)[0]);

    const updatedDocs = newDocs
      .map(({ _id }, index) => ({ _id, order: index }))
      .sort((a, b) => (a.order > b.order ? 1 : -1));

    const canceler = requestCanceler();

    this.requestCancelers.push(canceler);

    updateFunction(updatedDocs, canceler.token)
      .then(() => toast('success', 'Порядок обновлен!'))
      .catch(() => toast('error', 'Ошибка'));
  };

  renderList = (list, listType) => {
    const { ai, location } = this.props;

    const currentListType = location.pathname === '/ai-library/groups' ? 'groups' : 'ai';

    const itemClassName = listType === 'groups' ? 'neural-network-group' : 'neural-network';

    return (
      <div className="ai-library__list">
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable
            droppableId={`all-ai-items-${currentListType}`}
            direction="vertical"
            type={`ai-${currentListType}`}
          >
            {(providedDroppable) => (
              <div
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {
                  list.map((aiItem, index) => (
                    <Draggable
                      draggableId={String(aiItem._id)}
                      index={index}
                      key={aiItem._id}
                    >
                      {(providedDraggable) => (
                        <div
                          {...providedDraggable.draggableProps}
                          ref={providedDraggable.innerRef}
                          className={itemClassName}
                          key={aiItem._id}
                        >
                          <div className={`${itemClassName}__details`}>
                            { listType === 'ai' && (
                              <div className={`${itemClassName}__details-ai`}>
                                <span>{ aiItem.name }</span>

                                <p className="text-secondary mt-2 mb-2">{ aiItem.description }</p>

                                <div className="text-primary">{ aiItem.action }</div>
                              </div>
                            )}

                            { listType === 'groups' && (
                              <>
                                <div className={`${itemClassName}__details-preview`}>
                                  <img src={aiItem.image_path} alt={aiItem.name} />
                                </div>

                                <span>{ aiItem.name }</span>
                              </>
                            ) }
                          </div>

                          <div className={`${itemClassName}__actions`}>
                            <button
                              className={`${itemClassName}__action`}
                              disabled={ai?.isOrderingAiGroups}
                              type="button"
                              onClick={() => this.handleClickEdit(aiItem?._id, listType)}
                            >
                              <FontAwesomeIcon icon={['fa', 'pen']} />
                            </button>

                            <button
                              type="button"
                              className={`${itemClassName}__action`}
                              disabled={ai?.isOrderingAiGroups}
                              onClick={() => this.handleClickDelete(aiItem?._id, listType)}
                            >
                              <FontAwesomeIcon icon={['far', 'times']} />
                            </button>

                            <button
                              {...providedDraggable.dragHandleProps}
                              className={`${itemClassName}__action`}
                              disabled={ai?.isOrderingAiGroups}
                              type="button"
                            >
                              <FontAwesomeIcon icon={['far', 'bars']} />
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
                }
                {providedDroppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  render() {
    const { ai, location } = this.props;

    const currentListType = location.pathname === '/ai-library/groups' ? 'groups' : 'ai';
    const currentLang = queryString.parse(location?.search)?.lang || 'ru';
    const list = currentListType === 'ai' ? ai.aiList.docs : ai.groups.docs;

    return (
      <View
        errorMessage={errorMessages[ai?.error?.message]}
        isError={ai?.error && ['admin_privileges_required'].includes(ai?.error.message)}
        isFetching={ai?.isFetching}
        title="Библиотека нейросетей"
        viewClass="ai-library"
        content={(
          <>
            <div className="ai-library__header">
              <div className="ai-library__chose-section">
                <button
                  className={`font-weight-normal ${currentListType === 'ai' ? 'active' : ''}`}
                  type="button"
                  onClick={() => this.handleChangeQuery({ list: 'ai' })}
                >
                  Нейросети
                </button>

                <button
                  className={`font-weight-normal ${currentListType === 'groups' ? 'active' : ''}`}
                  type="button"
                  onClick={() => this.handleChangeQuery({ list: 'groups' })}
                >
                  Группы
                </button>
              </div>

              { this.renderLanguagesDropdown() }
            </div>

            <Link
              to={`/ai-library/${currentListType === 'ai' ? '' : 'groups/'}create${currentLang !== 'ru' ? `?lang=${currentLang}` : ''}`}
              className="btn btn-success pl-4 pr-4 mb-4"
            >
              Добавить
              { currentListType === 'ai' ? ' нейросеть' : ' группу' }
            </Link>

            { list?.length > 0 && this.renderList(list, currentListType) }

            {
              (!list?.length && !ai?.isFetching) && (
                <div className="ai-library__no-media">
                  <NoMedia
                    caption="Ничего не найдено"
                    icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                  />
                </div>
              )
            }
          </>
        )}
      />
    );
  }
}

AiLibrary.propTypes = {
  ai: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  onBulkUpdateAiGroups: PropTypes.func.isRequired,
  onBulkUpdateAIs: PropTypes.func.isRequired,
  onDeleteAi: PropTypes.func.isRequired,
  onDeleteAiGroup: PropTypes.func.isRequired,
  onGetAiGroupsList: PropTypes.func.isRequired,
  onGetAiList: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default AiLibrary;
