const selectCreativeStudioStyleCategories = (state) => state.creativeStudioStyleCategories;
const selectCreativeStudioStyleCategoriesError = (state) => state.creativeStudioStyleCategories.error;
const selectCreativeStudioStyleCategoriesIsFetching = (state) => state.creativeStudioStyleCategories.isFetching;
const selectCreativeStudioStyleCategoriesIsOrdered = (state) => state.creativeStudioStyleCategories.isOrdered;
const selectCreativeStudioStyleCategoriesDocs = (state) => state.creativeStudioStyleCategories.docs;

export {
  selectCreativeStudioStyleCategories,
  selectCreativeStudioStyleCategoriesError,
  selectCreativeStudioStyleCategoriesIsFetching,
  selectCreativeStudioStyleCategoriesIsOrdered,
  selectCreativeStudioStyleCategoriesDocs,
};
