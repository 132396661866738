import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

const TemplatesTemplateResources = ({ variations }) => {
  const resources = useMemo(() => {
    const stock = [];
    const ai = [];

    if (variations && variations?.length) {
      variations.forEach((variation) => {
        variation.data.slides.forEach((slide) => {
          if (slide._attribution) stock.push(slide._attribution);

          slide.objects.forEach((object) => {
            if (object._attribution && !object?._attribution_data?.ai) stock.push(object._attribution); // eslint-disable-line

            if (object?.style?.background?._attribution && !object?.style?.background?._attribution?.includes('creative-studio')) stock.push(object.style.background._attribution);

            if (object?.path?.includes('creative-studio') || object?._attribution_data?.ai) ai.push(object.path); // eslint-disable-line

            if (object?.style?.background?.path?.includes('creative-studio')) ai.push(object.style.background.path);

            if (object.type === 'mockup') {
              const smartObj = object?.mockup_data?.layers?.find((layer) => layer.type === 'smart-object');

              if (smartObj) {
                const paths = [...new Set(smartObj.children.filter((item) => item?._attribution).map((item) => item._attribution))];

                paths.forEach((item) => {
                  if (item?.includes('creative-studio')) ai.push(item);
                  else stock.push(item);
                });
              }
            }
          });
        });
      });
    }

    return { stock, ai };
  }, [variations]);

  const renderList = (name, items) => (
    items.length ? (
      <div className="template__row">
        <div className="template__label">{`${name}:`}</div>
        <div className="template__row-content template__tdr-resources">
          <ul>
            {items.map((item) => (
              <li key={item}><a href={item} target="_blank" rel="noreferrer">{item}</a></li>
            ))}
          </ul>
        </div>
      </div>
    ) : null
  );

  return (
    <>
      {renderList('Стоковые ресурсы', resources.stock)}
      {renderList('AI ресурсы', resources.ai)}
    </>
  );
};

TemplatesTemplateResources.propTypes = {
  variations: PropTypes.array,
};

export default memo(TemplatesTemplateResources);
