import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { s3Replace } from '../../../../../../utils';

const CreativeStudioImagesCommunityAsset = ({ data, isSelected, onClick }) => {
  const handleClick = () => onClick(isSelected ? null : data);

  return (
    <div
      className={classNames('creative-studio__comminity-asset', {
        'is-showed': data.community,
        'is-selected': isSelected,
      })}
      style={{ paddingTop: `${(data.info.height / data.info.width) * 100}%` }}
    >
      <button type="button" onClick={handleClick}>
        <div
          className="creative-studio__comminity-asset-image"
          style={{ backgroundImage: `url(${s3Replace((data?.sizes?.length && data.sizes.find((item) => item.width === 200)?.url) || data.url)})` }}
        />
      </button>
    </div>
  );
};

CreativeStudioImagesCommunityAsset.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(CreativeStudioImagesCommunityAsset);
