import axios from 'axios';
import { LOGOUT } from '../actions/auth';

const cancelToken = axios.CancelToken;

let cancelSource;

const cancelRequest = () => cancelSource.cancel();

export function requestCanceler() {
  const ct = axios.CancelToken;
  const cs = ct.source();

  return {
    token: cs.token,
    cancelRequest: () => cs.cancel(),
  };
}

export default (apiUrl, apiV2Url) => (store) => (next) => (action) => {
  if (!action.request) {
    return next(action);
  }

  let REQUEST; let SUCCESS; let FAILURE; let CANCELED; let PROGRESS;

  if (action.types) {
    [REQUEST, SUCCESS, FAILURE, CANCELED, PROGRESS] = action.types;
  } else {
    REQUEST = `${action.type}_REQUEST`;
    SUCCESS = action.type;
    FAILURE = `${action.type}_FAILURE`;
    CANCELED = `${action.type}_CANCELED`;
    PROGRESS = `${action.type}_PROGRESS`;
  }

  next({ type: REQUEST, ...(action.payload ? { payload: action.payload } : {}) });

  const { token } = store.getState().auth;

  if (action.request.cancelPrevious && !action.request.requestToken) cancelRequest();

  return new Promise((resolve, reject) => {
    cancelSource = cancelToken.source();

    const apiURL = action.request.apiv2 ? apiV2Url : apiUrl;
    const params = action.request.params || {};
    const adminParam = !action.request.directUrl && action.request.method?.toLowerCase() === 'get' ? { priv: 'admin' } : {};

    axios({
      method: action.request.method,
      url: `${action.request.directUrl ? '' : apiURL}${action.request.url}`,
      headers: {
        ...(token ? { 'X-Access-Token': token } : {}),
        ...(action.request.headers || {}),
      },
      cancelToken: action.request.requestToken || cancelSource.token,
      responseType: action.request.responseType,
      params: { ...params, ...adminParam },
      ...(action.request.data ? { data: action.request.data } : {}),
      ...(action.request.withCredentials ? {
        withCredentials: action.request.withCredentials,
      } : {}),
      onUploadProgress: (progressEvent) => next({ type: PROGRESS, response: progressEvent }),
    }).then(({ data }) => {
      const { request, type, ...rest } = action;

      next({
        type: SUCCESS,
        response: data,
        ...rest,
      });

      resolve(data);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        next({ type: CANCELED, response: 'canceled' });
      } else {
        let responseData = error.response && error.response.data
          ? error.response.data
          : null;

        if (
          error.response
          && responseData
          && error.response.request.responseType === 'arraybuffer'
          && responseData.toString() === '[object ArrayBuffer]'
        ) {
          responseData = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(responseData)),
          );
        }

        if (responseData && responseData.message && responseData.message === 'auth_required') {
          next({ type: LOGOUT });
        } else {
          next({
            type: FAILURE,
            response: responseData || { message: error.message },
            ...(action.payload ? { payload: action.payload } : {}),
          });
        }

        reject(responseData || { message: error.message });
      }
    });
  });
};
