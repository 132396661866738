import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { s3Replace, toast } from '../../../../../../utils';

import { getSignedUrl, sendFileToS3 } from '../../../../../../actions';

import withRequest from '../../../../../HOCs/withRequest';

import CreativeStudioImagesStylesStyleIcon from '../CreativeStudioImagesStylesStyleIcon/CreativeStudioImagesStylesStyleIcon';

const CreativeStudioImagesStylesStyleForm = ({
  createCanceler, defaultPreview, defaultName, defaultPrompt, dispatch, dragHandleProps, isDisabled, onCancel, onComplete,
}) => {
  const previewInputRef = useRef(null);

  const [preparedPreview, setPreparedPreview] = useState(null);

  const [isPreviewUploading, setIsPreviewUploading] = useState(false);
  const [preview, setPreview] = useState(defaultPreview);
  const [name, setName] = useState(defaultName);
  const [prompt, setPrompt] = useState(defaultPrompt);

  const resetPreviewFile = () => {
    previewInputRef.current.value = '';

    setPreparedPreview(null);
  };

  const uploadPreviewFile = (file, cb) => { // eslint-disable-line
    if (isPreviewUploading) return;

    setIsPreviewUploading(true);

    const requestGetSignedUrlCanceler = createCanceler();

    dispatch(getSignedUrl({
      file_name: file.name,
      file_type: file.type,
      upload_type: 'creative_studio_style_preview',
    }, requestGetSignedUrlCanceler.token))
      .then((response) => {
        const requestSendToS3Canceler = createCanceler();

        dispatch(sendFileToS3(response.signedRequest, file, requestSendToS3Canceler.token))
          .then(() => {
            cb(response.url);

            setPreview(response.url);
            setIsPreviewUploading(false);

            resetPreviewFile();
          })
          .catch(() => {
            setIsPreviewUploading(false);

            toast('error', 'Не удалось загрузить файл по подписанному URL');
          });
      })
      .catch(() => {
        setIsPreviewUploading(false);

        toast('error', 'Не удалось получить подписанный URL');
      });
  };

  const preparePreviewFile = useCallback(({ target }) => {
    if (!target.files.length) {
      resetPreviewFile();

      return;
    }

    const file = target.files[0];

    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        setPreparedPreview({
          preview: e.target.result,
          file,
        });
      };

      fileReader.onerror = () => {
        resetPreviewFile();
      };

      fileReader.readAsDataURL(file);
    }
  }, []);

  const handleDeletePreviewFile = () => {
    resetPreviewFile();

    setPreview(null);
  };

  const handleSelectPreviewFile = () => previewInputRef?.current?.click();

  const handleNameChange = (e) => setName(e.target.value);

  const handlePromptChange = (e) => setPrompt(e.target.value);

  const handleComplete = () => {
    if (preparedPreview) {
      uploadPreviewFile(preparedPreview.file, (preview_url) => {
        onComplete({
          preview_url,
          name,
          prompt,
        });
      });
    } else {
      onComplete({
        preview_url: preview,
        name,
        prompt,
      });
    }
  };

  const disabled = isDisabled || isPreviewUploading;

  const renderPreviewField = () => (
    <Form.File custom>
      <Form.File.Input
        accept=".png,.jpg,.jpeg"
        disabled={disabled}
        ref={previewInputRef}
        onChange={preparePreviewFile}
      />
    </Form.File>
  );

  return (
    <>
      <div
        className={`fileloader creative-studio__styles-style-preview${!preparedPreview && !preview ? ' creative-studio__styles-style-preview_empty' : ''}`}
      >
        {renderPreviewField()}
        {preparedPreview || preview ? (
          <>
            <img src={preparedPreview?.preview || s3Replace(preview)} alt="" />
            <div className="creative-studio__styles-style-preview-actions">
              <button
                className="btn creative-studio__styles-style-preview-button"
                disabled={disabled}
                type="button"
                onClick={handleDeletePreviewFile}
                title="Удалить превью"
              >
                <FontAwesomeIcon icon={['fas', 'trash']} />
              </button>
              <button
                className="btn creative-studio__styles-style-preview-button"
                disabled={disabled}
                type="button"
                onClick={handleSelectPreviewFile}
                title="Заменить превью"
              >
                <FontAwesomeIcon icon={['fas', 'upload']} />
              </button>
            </div>
          </>
        ) : (
          <>
            <CreativeStudioImagesStylesStyleIcon />
            <button // eslint-disable-line
              className="btn creative-studio__styles-style-preview-button"
              disabled={disabled}
              type="button"
              onClick={handleSelectPreviewFile}
              title="Выбрать превью"
            />
          </>
        )}
      </div>
      <Form.Control
        autoFocus
        as="textarea"
        className="creative-studio__styles-style-form-field creative-studio__styles-style-form-field_name"
        disabled={disabled}
        placeholder="Введите имя стиля..."
        value={name}
        onChange={handleNameChange}
      />
      <Form.Control
        as="textarea"
        className="creative-studio__styles-style-form-field"
        disabled={disabled}
        placeholder="Введите дополнительный промпт..."
        value={prompt}
        onChange={handlePromptChange}
      />
      <div className="creative-studio__styles-style-actions">
        <button
          className="template-bunch-item__button creative-studio__styles-style-button creative-studio__styles-style-button_complete"
          disabled={name === '' || prompt === '' || disabled}
          type="button"
          onClick={handleComplete}
        >
          <FontAwesomeIcon icon={['fas', 'check']} />
        </button>
        <button
          className="template-bunch-item__button creative-studio__styles-style-button creative-studio__styles-style-button_delete"
          disabled={disabled}
          type="button"
          onClick={onCancel}
        >
          <FontAwesomeIcon icon={['far', 'times']} />
        </button>
        <div {...(dragHandleProps || {})} />
      </div>
    </>
  );
};

CreativeStudioImagesStylesStyleForm.defaultProps = {
  defaultPreview: null,
};

CreativeStudioImagesStylesStyleForm.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  defaultPreview: PropTypes.string,
  defaultName: PropTypes.string.isRequired,
  defaultPrompt: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default withRequest(memo(CreativeStudioImagesStylesStyleForm));
