import { library, config } from '@fortawesome/fontawesome-svg-core';

import {
  // faPlus,
  // faPlay,
  // faCopy,
  // faCut as faCutSolid,
  faTrash,
  // faLock,
  // faUnlock,
  // faEyeSlash,
  // faEye,
  // faBringForward,
  // faSendBack,
  // faBringFront,
  // faSendBackward,
  // faTimesCircle,
  // faExclamationTriangle as faExclamationTriangleSolid,
  // faHashtag,
  // faTextWidth,
  // faCaretDown,
  // faQuestionCircle,
  faCog,
  faCommentAlt,
  faStar,
  // faStars,
  // faFont,
  // faAlignLeft,
  // faAlignCenter,
  // faAlignRight,
  // faBug,
  // faBold,
  // faItalic,
  // faUnderline,
  faEllipsisH,
  // faCaretUp,
  // faRunning,
  // faPaste,
  // faBracketsCurly,
  // faChevronUp as faChevronUpSolid,
  // faChevronDown as faChevronDownSolid,
  // faRepeat,
  // faSquare as faSquareSolid,
  // faCircle,
  // faHexagon,
  // faStar,
  // faTriangle,
  // faVideo as faVideoSolid,
  faUpload,
  // faUsers,
  // faUser,
  // faHistory as faHistorySolid,
  // faClipboardCheck,
  // faSearch as faSearchSolid,
  // faPause,
  // faVolume,
  // faVolumeMute,
  // faTimes as faTimesSolid,
  // faCircleNotch as faCircleNotchSolid,
  // faEllipsisV,
  faHeart as faHeartSolid,
  faSpinnerThird as faSpinnerThirdSolid,
  // faFilm,
  // faCloud,
  // faStop,
  // faVolumeOff,
  // faVolumeUp,
  // faFlask,
  // faFolder,
  // faFolderOpen,
  faBell,
  faInfo,
  faInfoCircle,
  faSignOutAlt,
  faSignInAlt,
  faCheck as faCheckSolid,
  // faPencilAlt,
  faPencil,
  // faImage,
  // faFilmAlt,
  // faPalette,
  faExclamationCircle,
  // faFile,
  // faSyncAlt,
  // faTrophy as faTrophySolid,
  // faText,
  // faSpinner,
  // faUndo,
  // faRedo,
  // faMusic as faMusicSolid,
  // faMinusCircle,
  // faPlusCircle,
  // faDownload,
  // faShapes as faShapesSolid,
  // faRocket,
  // faPaintBrush as faPaintBrushSolid,
  // faPoo as faPooSolid,
  // faPlusSquare,
  // faArrowAltRight,
  // faArrowAltLeft,
  // faArrowAltDown,
  // faArrowAltUp,
  // faArrowsV,
  // faArrowsH,
  // faExpandArrowsAlt,
  // faFillDrip,
  // faLockAlt,
  // faLockOpenAlt,
  // faWaveform,
  faEye,
  faImage,
  faEdit,
  faGripLines,
  faSyncAlt as faSyncAltSolid,
  faPen as faPenSolid,
  faPlus as faPlusSolid,
  faFlask as faFlaskSolid,
  faChevronDoubleRight as faChevronDoubleRightSolid,
  faPlaneArrival,
  faPlaneDeparture,
  faFlask,
  faTimes,
  faSync as faSyncSolid,
  faSearch as faSearchSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faTimesCircle as faTimesCircleSolid,
  faUndoAlt as faUndoAltSolid,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faTimes as faTimesLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faSpinnerThird as faSpinnerThirdLight,
  // faHistory as faHistoryLight,
  // faEdit,
  // faArrowRight,
  // faSadTear,
  // faTv,
  // faVideo as faVideoLight,
  // faCircleNotch as faCircleNotchLight,
  faCheck as faCheckLight,
  // faShapes as faShapesLight,
  // faPoo as faPooLight,
  // faHeart as faHeartLight,
  // faPaintBrush as faPaintBrushLight,
  // faMusic as faMusicLight,
  // faSearch as faSearchLight,
  // faBars,
  faUpload as faUploadLight,
  faFileAlt as faFileAltLight,
  faTimesCircle as faTimesCircleLight,
  faQuestionCircle as faQuestionCircleLight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faChevronLeft as faChevronLeftRegular,
  faChevronRight as faChevronRightRegular,
  faChevronDown as faChevronDownRegular,
  // faChevronUp as faChevronUpRegular,
  // faCropAlt,
  // faCheck as faCheckRegular,
  // faClock,
  // faCopy as faCopyRegular,
  // faExternalLink as faExternalLinkRegular,
  // faPaste as faPasteRegular,
  // faSpinnerThird as faSpinnerThirdRegular,
  // faCut as faCutRegular,
  // faSquare as faSquareRegular,
  // faMinusSquare,
  faCalendarAlt,
  // faCrosshairs,
  // faCircleNotch as faCircleNotchRegular,
  // faMoneyBillWaveAlt,
  // faTrophy as faTrophyRegular,
  // faLightbulb,
  // faChevronDown as faChevronDownRegular,
  // faImagePolaroid,
  // faLineHeight,
  // faKerning,
  faTimes as faTimesRegular,
  faBars as faBarsRegular,
} from '@fortawesome/pro-regular-svg-icons';

import {
  // faBuromobelexperte,
  faVk,
  // faDropbox,
  // faGoogleDrive,
  // faFacebookF,
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  // -------------- SOLID -------------------
  // faBars,
  faGripLines,
  faCommentAlt,
  faEye,
  faImage,
  faStar,
  faPlaneArrival,
  faPlaneDeparture,
  faFlask,
  faTimes,
  faUpload,
  // faHashtag,
  // faTextWidth,
  // faTimesCircle,
  // faPlus,
  // faExclamationTriangleSolid,
  // faPlay,
  // faBug,
  // faCopy,
  // faCutSolid,
  faTrash,
  // faLock,
  // faUnlock,
  // faEyeSlash,
  // faEye,
  // faBringForward,
  // faSendBack,
  // faBringFront,
  // faSendBackward,
  // faCaretDown,
  // faQuestionCircle,
  faCog,
  // faStars,
  // faFont,
  // faAlignLeft,
  // faAlignCenter,
  // faAlignRight,
  // faBold,
  // faItalic,
  // faUnderline,
  faEllipsisH,
  // faCaretUp,
  // faCaretDown,
  // faRunning,
  // faPaste,
  // faBracketsCurly,
  // faChevronUpSolid,
  // faChevronDownSolid,
  // faRepeat,
  // faSquareSolid,
  // faCircle,
  // faHexagon,
  // faStar,
  // faTriangle,
  // faVideoSolid,
  faUpload,
  // faUsers,
  // faUser,
  // faHistorySolid,
  // faClipboardCheck,
  // faSearchSolid,
  // faPause,
  // faVolume,
  // faVolumeMute,
  // faTimesSolid,
  // faCircleNotchSolid,
  // faEllipsisV,
  faHeartSolid,
  faSpinnerThirdSolid,
  // faFilm,
  // faCloud,
  // faStop,
  // faVolumeOff,
  // faVolumeUp,
  // faFlask,
  // faFolder,
  // faFolderOpen,
  faBell,
  faInfo,
  faInfoCircle,
  faSignOutAlt,
  faSignInAlt,
  faCheckSolid,
  // faPencilAlt,
  faPencil,
  // faImage,
  // faFilmAlt,
  // faPalette,
  faExclamationCircle,
  // faFile,
  // faSyncAlt,
  // faTrophySolid,
  // faText,
  // faSpinner,
  // faUndo,
  // faRedo,
  // faMusicSolid,
  // faMinusCircle,
  // faPlusCircle,
  // faDownload,
  // faShapesSolid,
  // faRocket,
  // faPaintBrushSolid,
  // faPooSolid,
  // faPlusSquare,
  // faArrowAltRight,
  // faArrowAltLeft,
  // faArrowAltDown,
  // faArrowAltUp,
  // faArrowsV,
  // faArrowsH,
  // faExpandArrowsAlt,
  // faFillDrip,
  // faLockAlt,
  // faLockOpenAlt,
  // faWaveform,
  faSyncAltSolid,
  faPenSolid,
  faPlusSolid,
  faFlaskSolid,
  faChevronDoubleRightSolid,
  faSyncSolid,
  faSearchSolid,
  faExclamationTriangleSolid,
  faTimesCircleSolid,
  faUndoAltSolid,
  // -------------- Light -------------------
  faTimesLight,
  faExclamationTriangleLight,
  faSpinnerThirdLight,
  // faHistoryLight,
  faEdit,
  // faArrowRight,
  // faSadTear,
  // faTv,
  // faVideoLight,
  // faCircleNotchLight,
  faCheckLight,
  // faShapesLight,
  // faPooLight,
  // faHeartLight,
  // faPaintBrushLight,
  // faMusicLight,
  // faSearchLight,
  faUploadLight,
  faFileAltLight,
  faBarsRegular,
  faTimesCircleLight,
  faQuestionCircleLight,
  // -------------- Regular -------------------
  // faExternalLinkRegular,
  // faCopyRegular,
  // faPasteRegular,
  faChevronLeftRegular,
  faChevronRightRegular,
  faChevronDownRegular,
  // faChevronUpRegular,
  // faCropAlt,
  // faCheckRegular,
  // faClock,
  // faSpinnerThirdRegular,
  // faCutRegular,
  // faSquareRegular,
  // faMinusSquare,
  faCalendarAlt,
  // faCrosshairs,
  // faCircleNotchRegular,
  // faMoneyBillWaveAlt,
  // faTrophyRegular,
  // faLightbulb,
  // faChevronDownRegular,
  // faImagePolaroid,
  // faLineHeight,
  // faKerning,
  faTimesRegular,
  // -------------- Brands -------------------
  // faBuromobelexperte,
  faVk,
  // faDropbox,
  // faGoogleDrive,
  // faFacebookF,
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faYoutube,
);
