import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useQueryLang } from '../../../hooks';

const CreativeStudioNav = () => {
  const location = useLocation();

  const isImagesRoute = typeof location?.pathname === 'string' && (location?.pathname.includes('/creative-studio/images'));

  const { currentLang, defaultLang } = useQueryLang(location);

  const queryParams = currentLang !== defaultLang ? `?lang=${currentLang}` : '';

  return (
    <div className="uppernav">
      <NavLink className={isImagesRoute ? ' is-active' : ''} to={`/creative-studio/images/community${queryParams}`}>
        Изображения
      </NavLink>
      {/* <NavLink className={isTransparentObjectsRoute ? ' is-active' : ''} to={`/creative-studio/transparent-objects${queryParams}`}>
        Объекты без фона
      </NavLink> */}
    </div>
  );
};

export default CreativeStudioNav;
