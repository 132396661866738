import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import apiMiddleware from '../middlewares/api';
import authMiddleware from '../middlewares/auth';
import tokenLocalStorage, { loadTokenState } from '../middlewares/token-local-storage';

import reducer from '../reducers';

import { authDefaultState } from '../reducers/auth';

import { queryParams } from '../utils';

export const history = createBrowserHistory();

// Build the middleware for API
const myApiMiddleware = apiMiddleware(window.SUPA_API_URL, window.SUPA_API_V2_URL);

const getMiddleware = () => applyMiddleware(
  routerMiddleware(history),
  myApiMiddleware,
  tokenLocalStorage,
  authMiddleware,
  thunk,
  promise,
  // process.env.NODE_ENV !== 'production' ? logger : undefined,
);

const queryToken = queryParams(window.location.search).get('token');
const token = queryToken || loadTokenState();

const initialState = {
  auth: {
    ...authDefaultState,
    token,
  },
};

export const store = createStore(
  reducer(history),
  initialState,
  composeWithDevTools(getMiddleware()),
);
