import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

import errorMessages from '../../../../constants/errors';

import { permissionNames } from '../../../../constants/common';
import { DELETE_ALERT_CONFIG, LIMIT_PER_PAGE } from '../../../../constants/access';

import { toast } from '../../../../utils';

import { getAdminUsers, deleteAdminPrivileges, RESET_ACCESS_ADMINS } from '../../../../actions';

import sweetAlert from '../../../HOCs/sweetAlert';
import withRequest from '../../../HOCs/withRequest';

import useScrollFix from '../../../Hooks/useScrollFix';

import View from '../../../Layout/View/View';

import AccessSubnav from '../../../Includes/Access/AccessSubnav/AccessSubnav';

import NoMedia from '../../../UI/NoMedia/NoMedia';
import Preloader from '../../../UI/Preloader/Preloader';

const AccessAdmin = memo(({
  data: {
    id, email, name, admin_permissions,
  },
  isCurrentUser,
  onDelete,
}) => {
  const handleDelete = () => typeof onDelete === 'function' && onDelete(id);

  return (
    <div className="access-admin">
      <div className="access-admin__info">
        <div className="access-admin__login">
          <Link to={`/access/admins/edit/${id}`}>{email}</Link>
        </div>
        <div className="access-admin__name">{name || ''}</div>
        <div className="access-admin__permissions">
          {admin_permissions?.length > 0 && admin_permissions.map((permission) => (
            <span key={permission}>{permissionNames[permission.toUpperCase().replace('-', '_')]}</span>
          ))}
        </div>
      </div>
      <div className="access-admin__actions">
        <Link className="access-admin__action" to={`/access/admins/edit/${id}`} title="Редактировать">
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </Link>
        {!isCurrentUser && (
          <Button className="access-admin__action access-admin__action-delete" variant="light" title="Удалить права администратора" onClick={handleDelete}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </Button>
        )}
      </div>
    </div>
  );
});

AccessAdmin.propTypes = {
  data: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
};

const AccessAdmins = ({ createCanceler, dispatch }) => {
  const admins = useSelector(({ accessAdmins }) => accessAdmins);
  const user = useSelector(({ auth }) => auth.user);

  const [scrollRef, scrollCallback, isEmptyList] = useScrollFix();

  const getAdmins = (page) => {
    const getAdminsRequest = createCanceler();

    dispatch(getAdminUsers({ page, limit: LIMIT_PER_PAGE, privilege: 'admin' }, false, getAdminsRequest.token))
      .then((res) => scrollCallback(res, getAdmins));
  };

  const loadMore = () => {
    if (admins.isFetching) return;

    getAdmins(admins.page + 1);
  };

  const handleDelete = useCallback((id) => {
    sweetAlert.fire({
      ...DELETE_ALERT_CONFIG,
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      html: 'Удалить права администратора?',
    }).then((result) => {
      if (!result.value) return;

      const deleteUserPrivRequest = createCanceler();

      dispatch(deleteAdminPrivileges(id, deleteUserPrivRequest.token))
        .then(() => toast('success', 'Права администратора удалены!'))
        .catch((error) => {
          const message = errorMessages[error.message] || error.message;

          toast('error', message);
        });
    });
  }, [createCanceler, dispatch]);

  useEffect(() => {
    getAdmins(1);
  }, []); // eslint-disable-line

  useEffect(() => () => {
    dispatch({ type: RESET_ACCESS_ADMINS });
  }, [dispatch]);

  return (
    <View
      errorMessage={errorMessages[admins.error?.message]}
      isError={admins.error && ['admin_privileges_required'].includes(admins.error.message)}
      viewClass="access"
      header={<AccessSubnav />}
      content={(
        <div className="access__admins">
          <Link to="/access/admins/create" className="btn btn-success access__btn">Добавить администратора</Link>
          <InfiniteScroll
            dataLength={admins.docs.length}
            hasMore={admins.page < admins.pages}
            loader={null}
            next={loadMore}
            style={{ overflow: 'visible' }}
          >
            {admins.docs.length > 0 && (
              <div className="access__admins-list" ref={scrollRef}>
                <AccessAdmin data={user} isCurrentUser />
                {admins.docs.map((item) => <AccessAdmin key={item.id} data={item} isCurrentUser={false} onDelete={handleDelete} />)}
              </div>
            )}
            {!admins.docs.length && !admins.isFetching && isEmptyList && (
              <div className="access__no-media">
                <NoMedia
                  caption="Ничего не найдено"
                  icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                />
              </div>
            )}
            {admins.isFetching && (
              <div className="access__preloader">
                <Preloader caption="Загружаем..." />
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
    />
  );
};

AccessAdmins.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(AccessAdmins));
