import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getAiGroup, updateAiGroup } from '../../../../actions';

import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import { useMount } from '../../../hooks';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import Preloader from '../../../UI/Preloader/Preloader';

import AiLibraryGroupForm from '../AiLibraryGroupForm/AiLibraryGroupForm';

const AiLibraryGroupEdit = ({
  createCanceler,
  dispatch,
  history,
  match,
  location,
}) => {
  const error = useSelector(({ ai }) => ai.error);
  const [currentAiGroup, isFetchingCurrentAiGroup] = useSelector(({ ai }) => [ai.currentAiGroup, ai.isFetchingCurrentAiGroup]);

  const getCurrentAiGroup = useCallback(() => {
    const canceler = createCanceler();

    return dispatch(getAiGroup(match?.params?.id, canceler.token));
  }, [createCanceler, match, dispatch]);

  const updateCurrentAi = useCallback((data) => {
    const canceler = createCanceler();

    return dispatch(updateAiGroup(currentAiGroup._id, {
      name: data.name,
      image_path: data.image_path,
      lang: data.lang,
    }, canceler.token));
  }, [createCanceler, currentAiGroup, dispatch]);

  useMount(() => {
    getCurrentAiGroup();
  });

  const handleEdit = useCallback(async (formData, callback) => {
    try {
      await updateCurrentAi(formData);

      toast('success', 'Группа обновлена!');

      history.push(`/ai-library/groups${formData.lang !== 'ru' ? `?lang=${formData.lang}` : ''}`);
    } catch (err) {
      toast('error', 'Прозошла ошибка');
    }

    callback();
  }, [history, updateCurrentAi]);

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
      viewClass="ai-library-group-edit"
      title="Редактировать группу"
      content={isFetchingCurrentAiGroup ? (
        <div>
          <Preloader caption="Загрузка..." />
        </div>
      ) : (
        <AiLibraryGroupForm
          buttonText="Редактировать"
          data={currentAiGroup}
          location={location}
          onSubmit={handleEdit}
        />
      )}
    />
  );
};

AiLibraryGroupEdit.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRequest(memo(AiLibraryGroupEdit));
