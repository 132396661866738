import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import errorMessages from '../../../../constants/errors';

import { getAi, updateAi } from '../../../../actions';

import { toast } from '../../../../utils';

import { useMount } from '../../../hooks';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import Preloader from '../../../UI/Preloader/Preloader';

import AiLibraryForm from '../AiLibraryForm/AiLibraryForm';

const AiLibraryEdit = ({
  createCanceler,
  dispatch,
  history,
  match,
  location,
}) => {
  const error = useSelector(({ ai }) => ai.error);
  const [currentAi, isFetchingCurrentAi] = useSelector(({ ai }) => [ai.currentAi, ai.isFetchingCurrentAi]);

  const getCurrentAi = useCallback(() => {
    const canceler = createCanceler();

    return dispatch(getAi(match?.params?.id, canceler.token));
  }, [createCanceler, match, dispatch]);

  const updateCurrentAi = useCallback((data) => {
    const canceler = createCanceler();

    return dispatch(updateAi(currentAi._id, {
      name: data.name,
      description: data.description,
      action: data.action,
      group_id: data.group_id,
      image_path: data.image_path,
      mode: data.mode,
      lang: data.lang,
    }, canceler.token));
  }, [createCanceler, currentAi, dispatch]);

  useMount(() => {
    getCurrentAi();
  });

  const handleEdit = useCallback(async (formData, callback) => {
    try {
      await updateCurrentAi(formData);

      toast('success', 'Данные обновлены!');

      history.push(`/ai-library${formData.lang !== 'ru' ? `?lang=${formData.lang}` : ''}`);
    } catch (err) {
      toast('error', 'Прозошла ошибка');
    }

    callback();
  }, [history, updateCurrentAi]);

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
      viewClass="ai-library-edit"
      title="Редактировать нейросеть"
      content={isFetchingCurrentAi ? (
        <div>
          <Preloader caption="Загрузка..." />
        </div>
      ) : (
        <AiLibraryForm
          buttonText="Редактировать"
          data={currentAi}
          location={location}
          onSubmit={handleEdit}
        />
      )}
    />
  );
};

AiLibraryEdit.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRequest(memo(AiLibraryEdit));
