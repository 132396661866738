import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAiGroupsList } from '../../../../actions';

import { aiActions, languages } from '../../../../constants/common';

import { toast } from '../../../../utils';

import withRequest from '../../../HOCs/withRequest';

import Preloader from '../../../UI/Preloader/Preloader';

import ImageUploader from '../ImageUploader/ImageUploader';

import './AiLibraryForm.scss';

const MODES = {
  all: 'Все режимы',
  image: 'Только изображение',
  video: 'Только анимация',
};

const EMPTY_FORM = {
  name: '',
  description: '',
  group_id: '',
  action: '',
  image_path: '',
  lang: 'ru',
  mode: 'all',
};

const AiLibraryForm = ({
  buttonText,
  createCanceler,
  dispatch,
  data,
  disabled,
  location,
  onSubmit = () => {},
}) => {
  const groups = useSelector(({ ai }) => ai.groups?.docs || []);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [formData, setFormData] = useState(
    cloneDeep(
      data?._id
        ? data
        : { ...EMPTY_FORM, lang: queryString.parse(location.search)?.lang || 'ru' },
    ),
  );
  const currentGroup = useMemo(() => groups.find(({ _id }) => _id === formData.group_id), [formData, groups]);

  const [file, setFile] = useState(null);
  const [formImageUrl, setFormImageUrl] = useState(formData.image_path);
  const imageUploaderRef = useRef(null);

  const handleChangeFormData = (props) => {
    setFormData((prev) => ({ ...prev, ...props }));
  };

  const handleValidateForm = () => {
    if (!formData.name.trim()) return 'Некорректное название';
    if (!formData.description.trim()) return 'Некорректное описание';
    if (!formData.group_id) return 'Не указана группа';
    if (!formData.action) return 'Не указано действие';

    return null;
  };

  const handleSubmit = async () => {
    if (!file && !formData.image_path) return toast('error', 'Отсутствует изображение');

    setIsSubmiting(true);

    const image_path = file ? await imageUploaderRef.current.uploadFileCover() : formData.image_path;
    const errorMessage = handleValidateForm();

    if (errorMessage) return toast('error', errorMessage);

    return onSubmit({ ...formData, image_path }, () => setIsSubmiting(false));
  };

  const handleGetAiGroups = useCallback(() => {
    const canceler = createCanceler();

    dispatch(getAiGroupsList({
      lang: formData.lang,
    }, canceler.token));
  }, [createCanceler, dispatch, formData.lang]);

  useEffect(() => {
    handleGetAiGroups();
  }, [handleGetAiGroups, formData.lang]);

  useEffect(() => {
    if (file) setFormImageUrl(URL.createObjectURL(file));
  }, [file]);

  return (
    <div className="ai-library-form">
      <span className="ai-library-form__link">
        <Link to={`/ai-library${formData.lang !== 'ru' ? `?lang=${formData.lang}` : ''}`}>Вернуться назад</Link>
      </span>

      <div className="ai-library-form__row">
        <div className="ai-library-form__label">Название:</div>

        <Form.Control
          disabled={disabled}
          value={formData.name}
          onChange={(e) => handleChangeFormData({ name: e.target.value })}
        />
      </div>

      <div className="ai-library-form__row">
        <div className="ai-library-form__label">Описание:</div>

        <Form.Control
          as="textarea"
          disabled={disabled}
          rows={5}
          value={formData.description}
          onChange={(e) => handleChangeFormData({ description: e.target.value })}
        />
      </div>

      <div className="ai-library-form__row">
        <div className="ai-library-form__label">Группа:</div>

        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
            { currentGroup && currentGroup?.name }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              groups.map((group) => (
                group._id !== currentGroup?._id ? (
                  <Dropdown.Item
                    as="button"
                    eventKey={group._id}
                    key={group._id}
                    onSelect={() => handleChangeFormData({ group_id: group._id })}
                  >
                    { group.name }
                  </Dropdown.Item>
                ) : null
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="ai-library-form__row">
        <div className="ai-library-form__label">Действие:</div>

        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
            { formData?.action }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              aiActions.map((action) => (
                action !== formData?.action ? (
                  <Dropdown.Item
                    as="button"
                    eventKey={action}
                    key={action}
                    onSelect={() => handleChangeFormData({ action })}
                  >
                    { action }
                  </Dropdown.Item>
                ) : null
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="ai-library-form__row">
        <div className="ai-library-form__label">Язык:</div>

        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
            { formData.lang && languages[formData.lang] }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              Object.keys(languages).map((lang) => (
                formData.lang !== lang ? (
                  <Dropdown.Item
                    as="button"
                    eventKey={lang}
                    key={lang}
                    onSelect={(value) => handleChangeFormData({ lang: value })}
                  >
                    { languages[lang] }
                  </Dropdown.Item>
                ) : null
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="ai-library-form__row">
        <div className="ai-library-form__label">Изображение:</div>

        <ImageUploader
          caption="Рекомендуемый размер 606х288"
          formImageUrl={formImageUrl}
          handleSetFile={setFile}
          file={file}
          ref={imageUploaderRef}
        />
      </div>

      <div className="ai-library-form__row ai-library-form__mode">
        <div className="ai-library-form__label">Доступно в режимах:</div>

        {Object.entries(MODES).map(([mode, modeTitle]) => (
          <Form.Check
            key={mode}
            checked={formData.mode === mode}
            className="form-check-custom"
            disabled={disabled}
            data-type={mode}
            id={`ai-library-form-${mode}`}
            inline
            label={modeTitle}
            name={mode}
            type="radio"
            onChange={({ target }) => handleChangeFormData({ mode: target.dataset.type })}
          />
        ))}
      </div>

      <Button
        className="btn-primary"
        disabled={disabled || handleValidateForm() || isSubmiting || (!file && !formData.image_path)}
        onClick={handleSubmit}
      >
        { isSubmiting && <FontAwesomeIcon className="mr-2" spin icon={['fa', 'spinner-third']} /> }

        { buttonText }

        { disabled && <Preloader inline small caption="" /> }
      </Button>
    </div>
  );
};

AiLibraryForm.propTypes = {
  buttonText: PropTypes.string,
  createCanceler: PropTypes.func.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    group_id: PropTypes.string,
    action: PropTypes.string,
    image_path: PropTypes.string,
    lang: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  location: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default withRequest(memo(AiLibraryForm));
