export const GET_GLOBAL_SETTINGS = 'GET_GLOBAL_SETTINGS';
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';

/* --------------- */
/* Action creators */

/* --------------- */

export function getGlobalSettings(requestToken) {
  return {
    type: GET_GLOBAL_SETTINGS,
    request: {
      method: 'get',
      url: '/admin/global-settings',
      requestToken,
    },
  };
}

export function updateGlobalSettings(data, requestToken) {
  return {
    type: UPDATE_GLOBAL_SETTINGS,
    request: {
      method: 'patch',
      url: '/admin/global-settings',
      data,
      requestToken,
    },
  };
}
