import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

import { toast } from '../../../../../../utils';

import {
  createCreativeStudioStyle,
  deleteCreativeStudioStyle,
  orderCreativeStudioStyles,
  saveCreativeStudioStylesOrder,
  updateCreativeStudioStyle,
} from '../../../../../../actions';

import sweetAlert from '../../../../../HOCs/sweetAlert';
import withRequest from '../../../../../HOCs/withRequest';

import { useQueryLang } from '../../../../../hooks';

import CreativeStudioImagesStylesCategoryForm from '../CreativeStudioImagesStylesCategoryForm/CreativeStudioImagesStylesCategoryForm';
import CreativeStudioImagesStylesStyle from '../CreativeStudioImagesStylesStyle/CreativeStudioImagesStylesStyle';
import CreativeStudioImagesStylesStyleForm from '../CreativeStudioImagesStylesStyleForm/CreativeStudioImagesStylesStyleForm';

const CreativeStudioImagesStylesCategory = ({
  createCanceler, data, dispatch, dragHandleProps, isDisabled, location, onDelete, onUpdate,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isCreateNewStyle, setIsCreateNewStyle] = useState(false);
  const [isStyleCreating, setIsStyleCreating] = useState(false);
  const [isStyleUpdating, setIsStyleUpdating] = useState(false);
  const [isStyleDeleting, setIsStyleDeleting] = useState(false);
  const [isStylesOrdered, setIsStylesOrdered] = useState(false);
  const [isStylesOrderSaving, setIsStylesOrderSaving] = useState(false);

  const { currentLang } = useQueryLang(location);

  const handleUpdate = useCallback((newName) => { // eslint-disable-line
    if (newName === data.name || newName === '') return setIsEdit(false);

    onUpdate(data._id, { name: newName.trim() }, () => setIsEdit(false));
  }, [data._id, data.name, onUpdate]);

  const handleDelete = () => onDelete(data._id);

  const handleEdit = () => setIsEdit(true);

  const handleCancel = () => setIsEdit(false);

  const handleCreateStyle = useCallback((styleData) => {
    setIsStyleCreating(true);

    const requestCreateStyleCanceler = createCanceler();

    dispatch(createCreativeStudioStyle({
      lang: currentLang,
      type: 'image',
      category_id: data._id,
      preview_url: styleData.preview_url,
      name: styleData.name.trim(),
      prompt: styleData.prompt.trim(),
    }, requestCreateStyleCanceler.token))
      .then(() => {
        toast('success', 'Стиль создан!');

        setIsCreateNewStyle(false);
        setIsStyleCreating(false);
      })
      .catch(() => {
        toast('error', 'Ошибка');

        setIsStyleCreating(false);
      });
  }, [data._id, dispatch, currentLang, createCanceler]);

  const handleCancelCreateStyle = () => setIsCreateNewStyle(false);

  const handleUpdateStyle = useCallback((id, styleData, successCallback) => {
    setIsStyleUpdating(true);

    const requestUpdateStyleCanceler = createCanceler();

    dispatch(updateCreativeStudioStyle(id, { ...styleData, category_id: data._id }, requestUpdateStyleCanceler.token))
      .then(() => {
        toast('success', 'Стиль обновлен!');

        setIsStyleUpdating(false);

        successCallback();
      })
      .catch(() => {
        setIsStyleUpdating(false);

        toast('error', 'Ошибка');
      });
  }, [data._id, dispatch, createCanceler]);

  const handleDeleteStyle = useCallback((id) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить стиль?',
    }).then((result) => {
      if (!result.value) return;

      setIsStyleDeleting(true);

      const requestDeleteStyleCanceler = createCanceler();

      dispatch(deleteCreativeStudioStyle(id, data._id, requestDeleteStyleCanceler.token))
        .then(() => {
          toast('success', 'Стиль удален!');

          setIsStyleDeleting(false);
        })
        .catch(() => {
          setIsStyleDeleting(false);

          toast('error', 'Ошибка');
        });
    });
  }, [data._id, dispatch, createCanceler]);

  const handleDragEnd = useCallback((result) => {
    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination) return;

    if (destination.index === source.index) return;

    if (type === `${data._id}-style`) {
      dispatch(orderCreativeStudioStyles(data._id, {
        sizeId: draggableId, // id перемещаемого итема
        currentPosition: source.index, // индекс текущего перемещаемого итема
        newPosition: destination.index, // индекс новой позиции в массиве
      }));

      setIsStylesOrdered(true);
    }
  }, [data._id, dispatch]);

  const handleSaveStylesOrder = useCallback(() => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите сохранить изменение порядка?',
    }).then((result) => {
      if (!result.value) return;

      setIsStylesOrderSaving(true);

      const prepared = data.styles.map((item, order) => ({ _id: item._id, order }));

      const requestSaveOrderCanceler = createCanceler();

      dispatch(saveCreativeStudioStylesOrder(prepared, requestSaveOrderCanceler.token))
        .then(() => {
          toast('success', 'Список успешно отсортирован');

          setIsStylesOrdered(false);

          setIsStylesOrderSaving(false);
        })
        .catch(() => {
          toast('error', 'Что-то пошло не так');

          setIsStylesOrderSaving(false);
        });
    });
  }, [createCanceler, data.styles, dispatch]);

  const isActionDisabled = isDisabled || isStyleCreating || isStyleUpdating || isStyleDeleting || isStylesOrderSaving;

  return (
    <div className="creative-studio__styles-category">
      {isEdit && (
        <CreativeStudioImagesStylesCategoryForm
          defaultName={data.name}
          isDisabled={isActionDisabled}
          onCancel={handleCancel}
          onComplete={handleUpdate}
          dragHandleProps={dragHandleProps}
        />
      )}
      {!isEdit && (
        <div className="creative-studio__styles-category-header">
          <span>{data.name}</span>
          <div className="creative-studio__styles-category-actions">
            <button
              className="template-bunch-item__button creative-studio__styles-category-button creative-studio__styles-category-button_edit"
              disabled={isActionDisabled}
              type="button"
              onClick={handleEdit}
            >
              <FontAwesomeIcon icon={['fas', 'pencil']} />
            </button>
            <button
              className="template-bunch-item__button creative-studio__styles-category-button creative-studio__styles-category-button_delete"
              disabled={isActionDisabled}
              type="button"
              onClick={handleDelete}
            >
              <FontAwesomeIcon icon={['far', 'times']} />
            </button>
            <button
              className="template-bunch-item__button creative-studio__styles-category-button creative-studio__styles-category-button_order"
              disabled={isActionDisabled}
              type="button"
              {...(dragHandleProps || {})}
            >
              <FontAwesomeIcon icon={['far', 'bars']} />
            </button>
          </div>
        </div>
      )}
      <div className="creative-studio__styles-category-main">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId={`${data._id}-all-styles`}
            direction="vertical"
            type={`${data._id}-style`}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="creative-studio__styles-styles"
              >
                {data.styles.map((style, index) => (
                  <Draggable draggableId={String(style._id)} index={index} key={style._id} isDragDisabled={isActionDisabled}>
                    {(provided2, snapshot) => (
                      <div
                        {...provided2.draggableProps}
                        ref={provided2.innerRef}
                        className={classNames('template-bunch__item', {
                          'template-bunch__item_dragging': snapshot.isDragging,
                        })}
                      >
                        <CreativeStudioImagesStylesStyle
                          data={style}
                          dragHandleProps={provided2.dragHandleProps}
                          isDisabled={isActionDisabled}
                          onDelete={handleDeleteStyle}
                          onUpdate={handleUpdateStyle}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isCreateNewStyle && (
          <div className="template-bunch-item creative-studio__styles-style creative-studio__styles-style-form">
            <CreativeStudioImagesStylesStyleForm
              defaultName=""
              defaultPrompt=""
              isDisabled={isActionDisabled}
              onCancel={handleCancelCreateStyle}
              onComplete={handleCreateStyle}
            />
          </div>
        )}
        <div
          className="creative-studio__actions"
          style={{ ...data.styles?.length && { marginTop: '-16px' } }}
        >
          <div className="creative-studio__styles-category-styles-actions">
            <button
              className="btn creative-studio__styles-category-style-btn"
              disabled={isActionDisabled || isCreateNewStyle}
              type="button"
              onClick={() => setIsCreateNewStyle(true)}
            >
              <FontAwesomeIcon icon={['fas', 'plus']} />
              Добавить стиль
            </button>
            {isStylesOrdered && (
              <Button
                disabled={isActionDisabled}
                onClick={handleSaveStylesOrder}
              >
                Сохранить изменение порядка
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CreativeStudioImagesStylesCategory.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  location: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default withRequest(memo(CreativeStudioImagesStylesCategory));
