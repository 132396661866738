import {
  GET_CREATIVE_STUDIO_STYLE_CATEGORIES,
  RESET_CREATIVE_STUDIO_STYLE_CATEGORIES,
  CREATE_CREATIVE_STUDIO_STYLE_CATEGORY,
  UPDATE_CREATIVE_STUDIO_STYLE_CATEGORY,
  DELETE_CREATIVE_STUDIO_STYLE_CATEGORY,
  ORDER_CREATIVE_STUDIO_STYLE_CATEGORIES,
  SAVE_CREATIVE_STUDIO_STYLE_CATEGORIES_ORDER,
  CREATE_CREATIVE_STUDIO_STYLE,
  UPDATE_CREATIVE_STUDIO_STYLE,
  DELETE_CREATIVE_STUDIO_STYLE,
  ORDER_CREATIVE_STUDIO_STYLES,
  SAVE_CREATIVE_STUDIO_STYLES_ORDER,
} from '../../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  isOrdered: false,
};

function returnError(response) {
  if (response.message) return { message: response.message, ...(response.params && { params: response.params }) };
  if (response.errors) return { message: response.errors[0] };

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_CREATIVE_STUDIO_STYLE_CATEGORIES}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
      };
    case `${SAVE_CREATIVE_STUDIO_STYLE_CATEGORIES_ORDER}_REQUEST`:
    case `${CREATE_CREATIVE_STUDIO_STYLE_CATEGORY}_REQUEST`:
    case `${UPDATE_CREATIVE_STUDIO_STYLE_CATEGORY}_REQUEST`:
    case `${DELETE_CREATIVE_STUDIO_STYLE_CATEGORY}_REQUEST`:
    case `${CREATE_CREATIVE_STUDIO_STYLE}_REQUEST`:
    case `${UPDATE_CREATIVE_STUDIO_STYLE}_REQUEST`:
    case `${DELETE_CREATIVE_STUDIO_STYLE}_REQUEST`:
    case `${SAVE_CREATIVE_STUDIO_STYLES_ORDER}_REQUEST`:
      return {
        ...state,
        error: null,
      };
    case GET_CREATIVE_STUDIO_STYLE_CATEGORIES:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
        isOrdered: false,
      };
    case ORDER_CREATIVE_STUDIO_STYLE_CATEGORIES:
      state.docs.splice(action.payload.newPosition, 0, state.docs.splice(action.payload.currentPosition, 1)[0]);

      return {
        ...state,
        docs: state.docs,
        isOrdered: true,
      };
    case SAVE_CREATIVE_STUDIO_STYLE_CATEGORIES_ORDER:
      return {
        ...state,
        isOrdered: false,
      };
    case CREATE_CREATIVE_STUDIO_STYLE_CATEGORY:
      return {
        ...state,
        docs: [...state.docs, action.response],
      };
    case DELETE_CREATIVE_STUDIO_STYLE_CATEGORY:
      return {
        ...state,
        docs: state.docs.filter((item) => item._id !== action.payload.id),
      };
    case UPDATE_CREATIVE_STUDIO_STYLE_CATEGORY:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.response._id) return item;

          return {
            ...item,
            ...action.response,
          };
        }),
      };
    case CREATE_CREATIVE_STUDIO_STYLE:
      return {
        ...state,
        docs: state.docs.map((category) => {
          if (category._id !== action.payload.category_id) return category;

          return {
            ...category,
            styles: [...category.styles, action.response],
          };
        }),
      };
    case UPDATE_CREATIVE_STUDIO_STYLE:
      return {
        ...state,
        docs: state.docs.map((category) => {
          if (category._id !== action.response.category_id) return category;

          return {
            ...category,
            styles: category.styles.map((style) => {
              if (style._id !== action.response._id) return style;

              return {
                ...style,
                ...action.response,
              };
            }),
          };
        }),
      };
    case DELETE_CREATIVE_STUDIO_STYLE:
      return {
        ...state,
        docs: state.docs.map((category) => {
          if (category._id !== action.payload.category_id) return category;

          return {
            ...category,
            styles: category.styles.filter((style) => style._id !== action.payload.id),
          };
        }),
      };
    case ORDER_CREATIVE_STUDIO_STYLES:
      return {
        ...state,
        docs: state.docs.map((category) => {
          if (category._id !== action.payload.category_id) return category;

          const newStyles = category.styles;

          newStyles.splice(action.payload.orderData.newPosition, 0, newStyles.splice(action.payload.orderData.currentPosition, 1)[0]);

          return {
            ...category,
            styles: newStyles,
          };
        }),
      };
    case `${GET_CREATIVE_STUDIO_STYLE_CATEGORIES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${SAVE_CREATIVE_STUDIO_STYLE_CATEGORIES_ORDER}_FAILURE`:
    case `${CREATE_CREATIVE_STUDIO_STYLE_CATEGORY}_FAILURE`:
    case `${UPDATE_CREATIVE_STUDIO_STYLE_CATEGORY}_FAILURE`:
    case `${DELETE_CREATIVE_STUDIO_STYLE_CATEGORY}_FAILURE`:
    case `${CREATE_CREATIVE_STUDIO_STYLE}_FAILURE`:
    case `${UPDATE_CREATIVE_STUDIO_STYLE}_FAILURE`:
    case `${DELETE_CREATIVE_STUDIO_STYLE}_FAILURE`:
    case `${SAVE_CREATIVE_STUDIO_STYLES_ORDER}_FAILURE`:
      return {
        ...state,
        error: action.response,
      };
    case RESET_CREATIVE_STUDIO_STYLE_CATEGORIES:
      return defaultState;
    default:
      return state;
  }
};
