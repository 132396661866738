import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { s3Replace } from '../../../../../../utils';

import CreativeStudioImagesStylesStyleForm from '../CreativeStudioImagesStylesStyleForm/CreativeStudioImagesStylesStyleForm';
import CreativeStudioImagesStylesStyleIcon from '../CreativeStudioImagesStylesStyleIcon/CreativeStudioImagesStylesStyleIcon';

const CreativeStudioImagesStylesStyle = ({
  data, dragHandleProps, isDisabled, onDelete, onUpdate,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleUpdate = useCallback((styleData) => { // eslint-disable-line
    if (styleData.name === '' || styleData.prompt === '') return setIsEdit(false);

    onUpdate(data._id, { name: styleData.name.trim(), prompt: styleData.prompt.trim(), preview_url: styleData.preview_url }, () => setIsEdit(false));
  }, [data._id, onUpdate]);

  const handleDelete = () => onDelete(data._id);

  const handleEdit = () => setIsEdit(true);

  const handleCancel = () => setIsEdit(false);

  return (
    <div className="creative-studio__styles-style">
      {isEdit ? (
        <CreativeStudioImagesStylesStyleForm
          defaultName={data.name}
          defaultPrompt={data.prompt}
          defaultPreview={data.preview_url}
          dragHandleProps={dragHandleProps}
          isDisabled={isDisabled}
          onCancel={handleCancel}
          onComplete={handleUpdate}
        />
      ) : (
        <>
          <div className="creative-studio__styles-style-info">
            <div className="creative-studio__styles-style-preview">
              {data.preview_url ? (
                <img src={s3Replace(data.preview_url)} alt="" />
              ) : (
                <CreativeStudioImagesStylesStyleIcon />
              )}
            </div>
            <div className="creative-studio__styles-style-name">{data.name}</div>
            <div className="creative-studio__styles-style-prompt">{data.prompt.length > 330 ? `${data.prompt.slice(0, 330)}...` : data.prompt}</div>
          </div>
          <div className="creative-studio__styles-style-actions">
            <button
              className="template-bunch-item__button creative-studio__styles-style-button creative-studio__styles-style-button_edit"
              disabled={isDisabled}
              type="button"
              onClick={handleEdit}
            >
              <FontAwesomeIcon icon={['fas', 'pencil']} />
            </button>
            <button
              className="template-bunch-item__button creative-studio__styles-style-button creative-studio__styles-style-button_delete"
              disabled={isDisabled}
              type="button"
              onClick={handleDelete}
            >
              <FontAwesomeIcon icon={['far', 'times']} />
            </button>
            <button
              className="template-bunch-item__button creative-studio__styles-style-button creative-studio__styles-style-button_order"
              disabled={isDisabled}
              type="button"
              {...(dragHandleProps || {})}
            >
              <FontAwesomeIcon icon={['far', 'bars']} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

CreativeStudioImagesStylesStyle.propTypes = {
  data: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default memo(CreativeStudioImagesStylesStyle);
