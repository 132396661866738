import {
  GET_INVOICES,
  PAY_INVOICE,
  SET_INVOICE_BONUS,
  UPDATE_INVOICE_PAY_DATE,
  UPDATE_INVOICE_CLOSE_DATE,
  UPDATE_INVOICE_DATE,
  UPDATE_INVOICE_COMPANY,
  UPDATE_INVOICE_VAT,
  RESET_INVOICES,
} from '../actions';

const defaultState = {
  error: null,
  isFetching: false,
  docs: [],
  lastActionType: null,
  page: 0,
  pages: 1,
};

function returnError(response, state) {
  if (response.message && state.page === 0) return { message: 'first_request_failed' };
  if (response.message) return response;

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_INVOICES}_REQUEST`:
      return {
        ...state,
        docs: action.payload.isSearch ? [] : state.docs,
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case `${PAY_INVOICE}_REQUEST`:
    case `${SET_INVOICE_BONUS}_REQUEST`:
    case `${UPDATE_INVOICE_PAY_DATE}_REQUEST`:
    case `${UPDATE_INVOICE_DATE}_REQUEST`:
    case `${UPDATE_INVOICE_CLOSE_DATE}_REQUEST`:
    case `${UPDATE_INVOICE_VAT}_REQUEST`:
      return {
        ...state,
        error: null,
        lastActionType: action.type,
      };
    case GET_INVOICES:
      return {
        ...state,
        ...action.response,
        docs: action.response.page === 1
          ? action.response.docs
          : [...state.docs, ...action.response.docs],
        isFetching: false,
        lastActionType: null,
      };
    case PAY_INVOICE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.response.invoice.id) return item;

          return {
            ...item,
            ...action.response.invoice,
          };
        }),
      };
    case SET_INVOICE_BONUS:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            bonus: action.response.bonus,
          };
        }),
      };
    case UPDATE_INVOICE_PAY_DATE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            pay_date: action.payload.pay_date,
          };
        }),
      };
    case UPDATE_INVOICE_DATE:
      return {
        ...state,
        docs: state.docs
          .map((item) => {
            if (item.id !== action.payload.id) return item;

            return {
              ...item,
              date: action.payload.date,
            };
          })
          .sort((a, b) => new Date(b.date) - new Date(a.date)),
      };
    case UPDATE_INVOICE_CLOSE_DATE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            close_date: action.payload.close_date,
          };
        }),
      };
    case UPDATE_INVOICE_COMPANY:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.response.id) return item;

          return {
            ...item,
            company_id: action.response.company_id,
            company: action.response.company,
          };
        }),
      };
    case UPDATE_INVOICE_VAT:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.response.id) return item;

          return {
            ...item,
            vat: action.response.vat,
          };
        }),
      };
    case `${GET_INVOICES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response, state),
        isFetching: false,
      };
    case `${PAY_INVOICE}_FAILURE`:
    case `${SET_INVOICE_BONUS}_FAILURE`:
      return {
        ...state,
        error: action.response,
      };
    case RESET_INVOICES:
      return defaultState;
    default:
      return state;
  }
};
