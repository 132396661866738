export const GET_CREATIVE_STUDIO_ASSETS = 'GET_CREATIVE_STUDIO_ASSETS';
export const UPDATE_CREATIVE_STUDIO_ASSET = 'UPDATE_CREATIVE_STUDIO_ASSET';

export function getCreativeStudioAssets(params, requestToken) {
  return {
    type: GET_CREATIVE_STUDIO_ASSETS,
    request: {
      method: 'get',
      url: '/creative-studio/assets',
      requestToken,
      params,
      apiv2: true,
    },
  };
}

export function updateCreativeStudioAsset(id, data, requestToken) {
  return {
    type: UPDATE_CREATIVE_STUDIO_ASSET,
    request: {
      method: 'patch',
      url: `/admin/creative-studio/assets/${id}`,
      requestToken,
      data,
      apiv2: true,
    },
  };
}
