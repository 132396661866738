import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { createAiGroup } from '../../../../actions';

import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import AiLibraryGroupForm from '../AiLibraryGroupForm/AiLibraryGroupForm';

const AiLibraryGroupCreate = ({
  createCanceler,
  dispatch,
  history,
  location,
}) => {
  const error = useSelector(({ ai }) => ai.error);

  const createNewAiGroup = useCallback((data) => {
    const canceler = createCanceler();

    return dispatch(createAiGroup(data, canceler.token));
  }, [createCanceler, dispatch]);

  const handleCreate = useCallback(async (formData, callback) => {
    try {
      await createNewAiGroup(formData);

      toast('success', 'Группа добавлена!');

      history.push(`/ai-library/groups${formData.lang !== 'ru' ? `?lang=${formData.lang}` : ''}`);
    } catch (err) {
      toast('error', 'Прозошла ошибка');
    }

    callback();
  }, [history, createNewAiGroup]);

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
      viewClass="ai-library-group-create"
      title="Добавить группу"
      content={(
        <AiLibraryGroupForm
          buttonText="Создать группу"
          location={location}
          onSubmit={handleCreate}
        />
      )}
    />
  );
};

AiLibraryGroupCreate.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRequest(memo(AiLibraryGroupCreate));
