export const GET_CREATIVE_STUDIO_STYLE_CATEGORIES = 'GET_CREATIVE_STUDIO_STYLE_CATEGORIES';
export const RESET_CREATIVE_STUDIO_STYLE_CATEGORIES = 'RESET_CREATIVE_STUDIO_STYLE_CATEGORIES';
export const CREATE_CREATIVE_STUDIO_STYLE_CATEGORY = 'CREATE_CREATIVE_STUDIO_STYLE_CATEGORY';
export const UPDATE_CREATIVE_STUDIO_STYLE_CATEGORY = 'UPDATE_CREATIVE_STUDIO_STYLE_CATEGORY';
export const DELETE_CREATIVE_STUDIO_STYLE_CATEGORY = 'DELETE_CREATIVE_STUDIO_STYLE_CATEGORY';
export const ORDER_CREATIVE_STUDIO_STYLE_CATEGORIES = 'ORDER_CREATIVE_STUDIO_STYLE_CATEGORIES';
export const SAVE_CREATIVE_STUDIO_STYLE_CATEGORIES_ORDER = 'SAVE_CREATIVE_STUDIO_STYLE_CATEGORIES_ORDER';
export const CREATE_CREATIVE_STUDIO_STYLE = 'CREATE_CREATIVE_STUDIO_STYLE';
export const UPDATE_CREATIVE_STUDIO_STYLE = 'UPDATE_CREATIVE_STUDIO_STYLE';
export const DELETE_CREATIVE_STUDIO_STYLE = 'DELETE_CREATIVE_STUDIO_STYLE';
export const ORDER_CREATIVE_STUDIO_STYLES = 'ORDER_CREATIVE_STUDIO_STYLES';
export const SAVE_CREATIVE_STUDIO_STYLES_ORDER = 'SAVE_CREATIVE_STUDIO_STYLES_ORDER';

export function getCreativeStudioStyleCategories(type, lang, requestToken) {
  return {
    type: GET_CREATIVE_STUDIO_STYLE_CATEGORIES,
    request: {
      method: 'get',
      url: '/admin/creative-studio/style-categories',
      requestToken,
      params: { type, lang },
      apiv2: true,
    },
  };
}

export function createCreativeStudioStyleCategory(data, requestToken) {
  return {
    type: CREATE_CREATIVE_STUDIO_STYLE_CATEGORY,
    request: {
      method: 'post',
      url: '/admin/creative-studio/style-categories',
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function updateCreativeStudioStyleCategory(id, data, requestToken) {
  return {
    type: UPDATE_CREATIVE_STUDIO_STYLE_CATEGORY,
    request: {
      method: 'patch',
      url: `/admin/creative-studio/style-categories/${id}`,
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function orderCreativeStudioStyleCategories(orderData) {
  return {
    type: ORDER_CREATIVE_STUDIO_STYLE_CATEGORIES,
    payload: orderData,
  };
}

export function saveCreativeStudioStyleCategoriesOrder(data, requestToken) {
  return {
    type: SAVE_CREATIVE_STUDIO_STYLE_CATEGORIES_ORDER,
    request: {
      method: 'post',
      url: '/admin/creative-studio/style-categories/order',
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function deleteCreativeStudioStyleCategory(id, requestToken) {
  return {
    type: DELETE_CREATIVE_STUDIO_STYLE_CATEGORY,
    request: {
      method: 'delete',
      url: `/admin/creative-studio/style-categories/${id}`,
      requestToken,
      apiv2: true,
    },
    payload: { id },
  };
}

export function createCreativeStudioStyle(data, requestToken) {
  return {
    type: CREATE_CREATIVE_STUDIO_STYLE,
    request: {
      method: 'post',
      url: '/admin/creative-studio/styles',
      data,
      requestToken,
      apiv2: true,
    },
    payload: data,
  };
}

export function updateCreativeStudioStyle(id, data, requestToken) {
  return {
    type: UPDATE_CREATIVE_STUDIO_STYLE,
    request: {
      method: 'patch',
      url: `/admin/creative-studio/styles/${id}`,
      data,
      requestToken,
      apiv2: true,
    },
  };
}

export function deleteCreativeStudioStyle(id, category_id, requestToken) {
  return {
    type: DELETE_CREATIVE_STUDIO_STYLE,
    request: {
      method: 'delete',
      url: `/admin/creative-studio/styles/${id}`,
      requestToken,
      apiv2: true,
    },
    payload: { id, category_id },
  };
}

export function orderCreativeStudioStyles(category_id, orderData) {
  return {
    type: ORDER_CREATIVE_STUDIO_STYLES,
    payload: { category_id, orderData },
  };
}

export function saveCreativeStudioStylesOrder(data, requestToken) {
  return {
    type: SAVE_CREATIVE_STUDIO_STYLES_ORDER,
    request: {
      method: 'post',
      url: '/admin/creative-studio/styles/order',
      data,
      requestToken,
      apiv2: true,
    },
  };
}
